import { tokenAddress, abi } from '@/betcoin'
import ggAbi from '@/GoodGame.json'
import oracleAbi from '@/Oracle.json'
import Web3 from 'web3'

export default {
  namespaced: true,

  state: {
    tokenAddress,
    connected: false,
    web3: null,
    address: null,
    provider: undefined, // lib
    // rpc events
    s_invites: [],
    s_bets: [],
  },
  getters: {
    invites: state => {
      return state.s_invites
    },
  },
  actions: {
    isBetting ({ state }, gameId) {
      for (var bet of state.s_bets) {
        if (bet.gameId === gameId && bet.sender === state.address) return true
      }
      return false
    },
    getBets ({ state }, gameId) {
      var ret = []
      for (var bet of state.s_bets) {
        if (bet.gameId === gameId) {
          ret.push(bet)
        }
      }
      return ret
    },
    async initRpc ({ state, dispatch, commit }) {
      var bprovider = window.ethereum
      if (bprovider && bprovider.chainId !== '0x13881') {
        await bprovider.request({ method: 'wallet_addEthereumChain', params: [{ chainId: '0x13881', chainName: 'Matic Mumbai Testnet', nativeCurrency: { name: 'maticmum', symbol: 'MATIC', decimals: 18 }, rpcUrls: ['https://rpc-mumbai.maticvigil.com'], blockExplorerUrls: ['https://mumbai-explorer.matic.today/'] }] })
        commit('snackbar/setSnackbar', {
          msg: 'Please use the matic mumbai network!',
          color: 'warning',
        }, { root: true })
        commit('snackbar/setValue', true, { root: true })
      }
      // const provider = new Web3.providers.WebsocketProvider('wss://ws-mumbai.matic.today/')
      // const web3 = new Web3(provider)
      // Check if browser is running Metamask
      const provider = new Web3.providers.WebsocketProvider('wss://ws-mumbai.matic.today/')
      const web3 = new Web3(provider)
      state.provider = provider

      state.web3 = web3
      state.platformContract = new web3.eth.Contract(ggAbi.abi, ggAbi.address)
      state.oracleContract = new web3.eth.Contract(oracleAbi.abi, oracleAbi.address)
      state.contract = new web3.eth.Contract(abi, tokenAddress)
      dispatch('listenRpc')
      dispatch('getLastEvents')
    },
    async connect ({ commit, state, dispatch }, router) {
      const web3 = new Web3(window.ethereum)
      try {
        await window.ethereum.request({ method: 'eth_requestAccounts' }) // await window.ethereum.enable()
      } catch (err) {
        console.log(err)
      }
      state.web3 = web3
      var provider = window.ethereum
      web3.setProvider(provider)
      state.platformContract = new web3.eth.Contract(ggAbi.abi, ggAbi.address)
      state.contract = new web3.eth.Contract(abi, tokenAddress)
      if (provider.chainId !== '0x13881') {
        provider.request({ method: 'wallet_addEthereumChain', params: [{ chainId: '0x13881', chainName: 'Matic Mumbai Testnet', nativeCurrency: { name: 'maticmum', symbol: 'MATIC', decimals: 18 }, rpcUrls: ['https://rpc-mumbai.maticvigil.com'], blockExplorerUrls: ['https://mumbai-explorer.matic.today/'] }] })
        commit('snackbar/setSnackbar', {
          msg: 'Please use the matic mumbai network!',
          color: 'warning',
        }, { root: true })
        commit('snackbar/setValue', true, { root: true })
      }
      state.provider = provider

      var accs = await web3.eth.getAccounts()
      var address = accs[0]
      state.address = address
      console.log('do set accs', address)

      window.ethereum.on('accountsChanged', async function (accounts) {
        console.log('updata', accounts)

        var accs = await web3.eth.getAccounts()
        var address = accs[0]
        state.address = address
        dispatch('wallet/getBalance', {}, { root: true })
        // await state.contract.methods.balanceOf(address).call().then(f => { state.totalBalance = f })
        // update balance, profile and etc...
        state.undefined = false
        state.unlocked = false
        dispatch('wallet/disconnect', { root: true })
      })
      state.connected = true
      dispatch('wallet/getBalance', {}, { root: true })
      dispatch('wallet/connectWs', { router, web3 }, { root: true })
      dispatch('listenRpc')
    },
    async disconnect ({ commit, state }) {
      console.log('calling close!', state.provider, state.web3)
      if (state.provider.close) {
        await state.provider.close()
        state.provider = null
        console.log('called close!')
      }
      state.connected = false
    },
    async getLastEvents ({ commit, state, dispatch }) {
      const avgBlockTime = 2 // 2 seconds
      var lastBlock = 0
      state.web3.eth.getBlockNumber().then(async curBlockN => {
        console.log('do req block n', curBlockN)
        if (lastBlock === 0) {
          var nBlocks = ((60 * 60 * 8) / avgBlockTime)
          // if (nBlocks > 5000) nBlocks = 5000
          lastBlock = curBlockN - nBlocks
          console.log('do req block n', curBlockN, nBlocks)
        }
        var fromBlock = lastBlock
        await state.platformContract.getPastEvents('MatchCreated', {
          fromBlock,
          toBlock: curBlockN,
        }, function (error, events) {
          if (error) return console.log('err', error)
          lastBlock = curBlockN
          var invs = state.s_invites
          for (var event of events) {
            var block = event.blockNumber

            var { gameId, maxPlayer, method, mode, oracle, region, sender, expireTime } = event.returnValues
            var nChop = 1
            while (region.substr(-nChop, 1) === '0') {
              nChop++
            }
            region = state.web3.utils.toAscii(region.substr(0, region.length - nChop + 1))
            var e = { state: 0, block, gameId, maxPlayer, method, mode, oracle, region, sender, expireTime }
            invs.push(e)
          }
        })
        state.platformContract.getPastEvents('MatchStarted', {
          fromBlock,
          toBlock: curBlockN,
        }, async function (error, events) {
          if (error) return console.log('err', error)
          console.log('got event3..', events)
          for (var event of events) {
            var { gameId, lockTime, oracle } = event.returnValues
            var inv = state.s_invites.find(i => i.gameId === gameId && i.oracle === oracle)
            if (inv) {
              inv.lockTime = lockTime
              inv.state = 1
            }
          }
        })
        state.platformContract.getPastEvents('MatchFinished', {
          fromBlock,
          toBlock: curBlockN,
        }, async function (error, events) {
          if (error) return console.log('err', error)
          console.log('got event4..', events)
          for (var event of events) {
            var { gameId, oracle } = event.returnValues
            var inv = state.s_invites.find(i => i.gameId === gameId && i.oracle === oracle)
            if (inv) {
              // get winners
              var winners = []
              var oracleState = Object.assign({}, await state.platformContract.methods.getOracleMatchState(gameId).call())
              for (var i = 0; i < oracleState.winners.length; i++) {
                var player = oracleState.winners[i]
                var nChop = 1
                while (player.substr(-nChop, 1) === '0') {
                  nChop++
                }
                winners[i] = state.web3.utils.toAscii(player.substr(0, player.length - nChop + 1))
              }
              inv.externalGameId = oracleState.externalGameId
              inv.winners = winners
              inv.state = 2
            }
          }
        })
        await state.platformContract.getPastEvents('BetCreated', {
          fromBlock,
          toBlock: curBlockN,
        }, function (error, events) {
          if (error) return console.log('err', error)
          // console.log('got event2..', events)
          var bets = state.s_bets
          for (var event of events) {
            var block = event.blockNumber
            var { oracle, sender, gameId, player, total } = event.returnValues

            var nChop = 1
            while (player.substr(-nChop, 1) === '0') {
              nChop++
            }
            player = state.web3.utils.toAscii(player.substr(0, player.length - nChop + 1))

            console.log('goocii')
            bets.push({ block, oracle, gameId, sender, player, total })
          }
        })
        state.platformContract.getPastEvents('BetRemoved', {
          fromBlock,
          toBlock: curBlockN,
        }, async function (error, events) {
          if (error) return console.log('err', error)
          // console.log('got event2..', events)
          var bets = state.s_bets
          for (var event of events) {
            var block = event.blockNumber

            var { oracle, sender, gameId } = event.returnValues
            for (var i = 0; i < bets.length; i++) {
              var bet = bets[i]
              if (bet.gameId === gameId && bet.oracle === oracle && bet.sender === sender && bet.block <= block) {
                bets.splice(i, 1)
              }
            }

            console.log('rmv goocii')
            // rmv_bets.push({ block, oracle, gameId, time, sender, player, total })
          }
        })
        state.platformContract.getPastEvents('Collected', {
          fromBlock,
          toBlock: curBlockN,
        }, async function (error, events) {
          if (error) return console.log('err', error)
          console.log('got event5..', events)
        })
        dispatch('tryBrowserConnect')
      })
    },
    async tryBrowserConnect ({ state, dispatch }) {
      let bweb3
      if (window.ethereum) {
        bweb3 = new Web3(window.ethereum)
      } else if (window.web3) {
        bweb3 = new Web3(window.web3.currentProvider)
      }
      var accs = await bweb3.eth.getAccounts()
      if (bweb3 && accs.length) {
        // web3 = bweb3
        console.log('got acc', accs)
        dispatch('connect')
      }
    },
    async listenRpc ({ commit, state }) {
      console.log('zz')
      state.platformContract.events.MatchCreated({}, async function (error, event) {
        // we only increment credits when we receive tokens
        if (error) return console.log('error', error)
        var block = event.blockNumber
        const blockData = await state.web3.eth.getBlock(event.blockNumber)
        var time = blockData.timestamp

        var { gameId, maxPlayer, method, mode, oracle, region, sender, expireTime } = event.returnValues
        var nChop = 1
        while (region.substr(-nChop, 1) === '0') {
          nChop++
        }
        region = state.web3.utils.toAscii(region.substr(0, region.length - nChop + 1))
        var e = { state: 0, block, gameId, maxPlayer, method, mode, oracle, region, sender, time, expireTime }
        state.s_invites.push(e)
      })
      state.platformContract.events.BetCreated({}, async function (error, event) {
        var bets = state.s_bets
        // we only increment credits when we receive tokens
        if (error) return console.log('error', error)
        var block = event.blockNumber
        const blockData = await state.web3.eth.getBlock(event.blockNumber)
        var time = blockData.timestamp
        var { oracle, sender, gameId, player, total } = event.returnValues

        var nChop = 1
        while (player.substr(-nChop, 1) === '0') {
          nChop++
        }
        player = state.web3.utils.toAscii(player.substr(0, player.length - nChop + 1))

        console.log('goocii')
        bets.push({ block, oracle, gameId, time, sender, player, total })
      })
      state.platformContract.events.BetRemoved({}, async function (error, event) {
        var bets = state.s_bets
        // we only increment credits when we receive tokens
        if (error) return console.log('error', error)
        var block = event.blockNumber

        var { oracle, sender, gameId } = event.returnValues
        for (var i = 0; i < bets.length; i++) {
          var bet = bets[i]
          if (bet.gameId === gameId && bet.oracle === oracle && bet.sender === sender && bet.block <= block) {
            bets.splice(i, 1)
          }
        }
      })
    },
    // actions
    async createMatch ({ commit, state }, { node, token, maxPlayers, region, mode, shareMethod }) {
      var tx = state.platformContract.methods.createMatch(node, token, maxPlayers, state.web3.utils.asciiToHex(region), mode, shareMethod)
      var gas = await tx.estimateGas({ from: state.address })
      var c = await tx.send({ from: state.address, gas })
      return c
    },
    async aproveBet ({ commit, state }, value) {
      var weth = state.web3.utils.toWei(value.toString(), 'ether')
      var allow = await state.contract.methods.allowance(state.address, ggAbi.address).call()
      console.log('allow', allow, allow / 1000000000000000000, weth, allow / 1000000000000000000 < value)
      if (allow !== weth) {
        var approv = await state.contract.methods.approve(ggAbi.address, weth).send({ from: state.address })
        console.log('aprov', approv)
      }
      return true
    },
    async placeBet ({ commit, state }, { gameId, alias, value }) {
      var weth = state.web3.utils.toWei(value.toString(), 'ether')

      var allow = await state.contract.methods.allowance(state.address, ggAbi.address).call()
      console.log('allow', allow, allow / 1000000000000000000, weth, allow / 1000000000000000000 < value)
      if (allow !== weth) {
        var approv = await state.contract.methods.approve(ggAbi.address, weth).send({ from: state.address })
        console.log('aprov', approv)
      }

      // var c = await this.platformContract.methods.createMatch('0x1c4e53E70dA3dF72aF12c772Ab605a86Cf10C7dc', 8, this.web3.utils.asciiToHex(region), this.web3.utils.asciiToHex(mode), '1').send({ from: this.address }).then(f => { console.log('bal', f) })
      var tx = state.platformContract.methods.placeBet(gameId, state.web3.utils.asciiToHex(alias.toLowerCase()), weth)
      var gas = await tx.estimateGas({ from: state.address })
      console.log('gas', gas)
      return await tx.send({ from: state.address, gas }).then(f => { console.log('bal', f) })
    },
    async removeBet ({ commit, state }, gameId) {
      var tx = state.platformContract.methods.removeBet(gameId)
      var gas = await tx.estimateGas({ from: state.address })
      return await tx.send({ from: state.address, gas })
    },
    async collectReward ({ commit, state }, gameId) {
      var tx = state.platformContract.methods.collectReward(gameId)
      var gas = await tx.estimateGas({ from: state.address })
      return await tx.send({ from: state.address, gas })
    },
    async getLastMatchs ({ commit, state }, count) {
      return await state.platformContract.methods.getLastMatchs(count).call()
    },
    async getMatch ({ commit, state }, gameId) {
      return await state.platformContract.methods.getMatch(gameId).call()
    },
    async getMatchOdds ({ commit, state }, gameId) {
      return await state.platformContract.methods.getMatchOdds(gameId).call()
    },
    async getPayoutValue ({ commit, state }, gameId) {
      return await state.platformContract.methods.getPayoutValue(gameId).call()
    },
    async getOracleMatchState ({ commit, state }, gameId) {
      return await state.platformContract.methods.getOracleMatchState(gameId).call()
    },
    async getMatchBettors ({ commit, state }, gameId) {
      return await state.platformContract.methods.getMatchBettors(gameId).call()
    },
    async getMatchPlayers ({ commit, state }, gameId) {
      return await state.platformContract.methods.getMatchPlayers(gameId).call()
    },
    async getBet ({ commit, state }, { address, gameId }) {
      return await state.platformContract.methods.getBet(address, gameId).call()
    },
    async getLastMatchIdsBettedByBettor ({ commit, state }, { creator, count }) {
      return await state.platformContract.methods.getLastMatchIdsBettedByBettor(creator, count).call()
    },
  },
}
