import axios from 'axios'
export default {
  namespaced: true,

  state: {
    // mainHost: 'https://127.0.0.1:8000/gg', // local host
    mainHost: 'https://api.goodgame.video/gg', // main host
    tournaments: {},
    games: {},
    streams: {},
    oracles: null,
    ttvHardlist: {},
    connected: false,
    unlocked: false,
    web3: undefined,
    address: '',
    invites: [],
    // lol helper..
    championList: [],
    timeTick: 0,
  },
  actions: {
    async initPlatform ({ state, dispatch }) {
      dispatch('getOracles')
      dispatch('getTtvHardlist')
      dispatch('getChampions')
      state.timeTick = (new Date()).getTime() / 1000
      setInterval(function () {
        state.timeTick += 1
      }, 1000)
    },
    async getTournaments ({ commit, state }) {
      var ret = (await axios.get(state.mainHost + '/tournaments')).data
      state.tournaments = ret
    },
    async getInvites ({ commit, state }) {
      state.invites = (await axios.get(state.mainHost + '/invites')).data
    },
    async getGames ({ commit, state }) {
      state.games = (await axios.get(state.mainHost + '/games')).data
    },
    async getStreams ({ commit, state }) {
      state.streams = (await axios.get(state.mainHost + '/streams')).data
    },
    async getOracles ({ commit, state }) {
      state.oracles = (await axios.get(state.mainHost + '/oracles')).data
    },
    async getTtvHardlist ({ commit, state }) {
      state.ttvHardlist = (await axios.get(state.mainHost + '/ttvHardlist')).data
      // console.log('ttv', state.ttvHardlist)
    },
    async getChampions ({ state }) {
      const response = await fetch(`https://ddragon.leagueoflegends.com/cdn/11.15.1/data/en_US/champion.json`)
      const body = await response.json()
      var championData = body.data

      var __list = []
      for (var i in championData) {
        __list[championData[i].key] = i
      }

      // this.championList.push(__list)
      state.championList = __list
      // console.log('got list!')
    },
  },
}
