import axios from 'axios'

export default {
  namespaced: true,

  state: {
    profiles: {},
  },

  actions: {
    getProfile: async ({ state, rootState, dispatch }, addr) => {
      var host = rootState.platform.mainHost
      var ret = (await axios.get(host + '/profile?addr=' + addr)).data
      // this.pinfo = ret
      var ar = {}
      ar[addr] = ret
      dispatch('parseProfiles', ar)
    },
    getProfiles: async ({ state, rootState }) => {
      var host = rootState.platform.mainHost
      var ret = (await axios.get(host + '/profile_list')).data
      state.profiles = ret
    },
    parseProfiles: async ({ commit, state }, profileList) => {
      state.profiles = {
        ...state.profiles,
        ...profileList,
      }
    },
  },
}
