import { set, toggle } from '@/utils/vuex'

export default {
  namespaced: true,

  state: {
    drawer: false,
    msg: false,
    friend_list: {},
    chats: {},
    msgAddr: '',
    friends: [
      {
        id: 1,
        name: 'John Smith',
        online: true,
        game: {
          id: 10,
          logo: 'games/storm-peak/avatar.png',
          name: 'Battle of StormPeak',
        },
      },
      {
        id: 2,
        name: 'Jane Smith',
        online: true,
      },
      {
        id: 3,
        name: 'Jimmy Doe',
        online: false,
      },
      {
        id: 4,
        name: 'Charles Edward Cheese',
        online: false,
      },
    ],
  },

  getters: {
    online: state => {
      const total = Object.keys(state.friend_list).length
      const online = Object.values(state.friend_list).filter(state => state === 2).length

      return `(${online}/${total})`
    },
  },
  actions: {
    setMsgAddr: ({ state }, msgAddr) => {
      state.msgAddr = msgAddr
    },
  },

  mutations: {
    setDrawer: set('drawer'),
    setMsgDrawer: set('msg'),
    toggleDrawer: toggle('drawer'),
  },
}
