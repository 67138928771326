import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from '@/store/modules/app'
import cfg from '@/store/modules/cfg'
import rpc from '@/store/modules/rpc'
import snackbar from '@/store/modules/snackbar'
import platform from '@/store/modules/platform'
import friends from '@/store/modules/friends'
import profiles from '@/store/modules/profiles'
import wallet from '@/store/modules/wallet'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    app,
    rpc,
    cfg,
    snackbar,
    platform,
    friends,
    profiles,
    wallet,
  },
  actions: {
    init: async () => {
      await Promise.all([])
    },
  },
})

store.dispatch('init')

export default store
