import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: () => ({ x: 0, y: 0 }),
  routes: [
    {
      path: '/',
      name: 'Home',
      component: () => import('@/views/home/Index'),
    },
    // {
    //   path: '/article/:slug',
    //   name: 'Article',
    //   component: () => import('@/views/article/Index'),
    // },
    // {
    //   path: '/library',
    //   name: 'Library',
    //   component: () => import('@/views/library/Index'),
    // },
    {
      path: '/gameResults',
      name: 'Game Results',
      component: () => import('@/views/game-results/Index'),
    },
    {
      path: '/oracles',
      name: 'Streams',
      component: () => import('@/views/oracles/Index'),
    },
    {
      path: '/streams',
      name: 'Streams',
      component: () => import('@/views/featured/Streams'),
    },
    {
      path: '/stream/:steamId',
      name: 'Stream',
      component: () => import('@/views/stream/Index'),
    },
    {
      path: '/gamestreams',
      name: 'Game Streams',
      component: () => import('@/views/featured/GameStreams'),
    },
    {
      path: '/esports',
      name: 'ESports',
      component: () => import('@/views/esports/Index'),
    },
    {
      path: '/twitchstreams',
      name: 'Twitch Streams',
      component: () => import('@/views/featured/TwitchStreams'),
    },
    {
      path: '/tournaments',
      name: 'Tournaments',
      component: () => import('@/views/tournaments/Index'),
    },
    {
      path: '/profile/:addr',
      name: 'profile',
      component: () => import('@/views/profile/Index'),
    },
    {
      path: '/profile',
      name: 'profile',
      component: () => import('@/views/profile/Index'),
    },
    {
      path: '/profiles',
      name: 'profiles',
      component: () => import('@/views/profile/List'),
    },
    {
      path: '/invites',
      name: 'invites',
      component: () => import('@/views/invites/Index'),
    },
    {
      path: '/results',
      name: 'results',
      component: () => import('@/views/results/Index'),
    },
    {
      path: '/invite/:addr',
      name: 'invite',
      component: () => import('@/views/invites/Detail'),
    },
    {
      path: '/connect_api',
      name: 'connect_api',
      component: () => import('@/views/connect_api/Index'),
    },
    // {
    //   path: '/store',
    //   name: 'Store',
    //   component: () => import('@/views/store/Index'),
    // },
    // {
    //   path: '/nodes',
    //   name: 'Nodes',
    //   component: () => import('@/views/nodes/Index'),
    // },
    // {
    //   path: '/nodes/:id',
    //   name: 'NodePage',
    //   component: () => import('@/views/node-page/Index'),
    // },
    // {
    //   path: '/result/:id',
    //   name: 'ResultPage',
    //   component: () => import('@/views/result-page/Index'),
    // },
    {
      path: '/settings',
      name: 'Settings',
      component: () => import('@/views/settings/Index'),
    },
    // {
    //   path: '/store/games/:id',
    //   name: 'StorePage',
    //   component: () => import('@/views/store-page/Index'),
    // },
  ],
})

// Bootstrap Analytics
// Set in .env
// https://github.com/MatteoGabriele/vue-analytics
if (process.env.VUE_APP_GOOGLE_ANALYTICS) {
  Vue.use(require('vue-analytics').default, {
    id: process.env.VUE_APP_GOOGLE_ANALYTICS,
    router,
    autoTracking: {
      page: process.env.NODE_ENV !== 'development',
    },
  })
}

export default router
