<template>
  <v-app :style="{background: $vuetify.theme.themes[theme].background}">
    <core-app-bar />

    <core-drawer />

    <core-view />

    <core-messages />

    <core-friends />

    <core-snackbar />
  </v-app>
</template>

<script>
  export default {
    components: {
      CoreAppBar: () => import('@/components/core/AppBar'),
      CoreDrawer: () => import('@/components/core/Drawer'),
      CoreMessages: () => import('@/components/core/Messages'),
      CoreFriends: () => import('@/components/core/Friends'),
      CoreSnackbar: () => import('@/components/core/Snackbar'),
      CoreView: () => import('@/components/core/View'),
    },
    computed: {
      theme () {
        return (this.$vuetify.theme.dark) ? 'dark' : 'light'
      },
    },
  }
</script>

<style>
.v-card {
  border-radius: 6px;
}
a {
  text-decoration: none;
}
</style>
