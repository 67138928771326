/* eslint- */

import Vue from 'vue'
import Vuetify, { VLayout, VFlex } from 'vuetify/lib'
import '@mdi/font/css/materialdesignicons.css'

const locales = {
  en: require('vuetify/es5/locale/en'),
  fr: require('vuetify/es5/locale/fr'),
}

const current = navigator.language.split('-')[0]

Vue.use(Vuetify, {
  components: {
    VFlex,
    VLayout,
  },
})

export default new Vuetify({
  locales,
  current,
  icons: {
    iconfont: 'mdi', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
  theme: {
    dark: false,
    themes: {
      dark: {
        menu: '#2B2E35',
        background: '#202328',
        primary: '#F7631D',
        accent: '#4CBB99',
        secondary: '#7BC6FF',
        success: '#4CAF50',
        info: '#2196F3',
        warning: '#FB8C00',
        error: '#FF5252',
      },
      light: {
        primary: '#1689E7',
        accent: '#4CBB99',
        secondary: '#7BC6FF',
        success: '#4CAF50',
        info: '#2196F3',
        warning: '#FB8C00',
        error: '#FF5252',
        background: '#F5F5F5',
      },
    },
  },
})
