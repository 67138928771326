import { set } from '@/utils/vuex'

export default {
  namespaced: true,

  state: {
    ttvLanguages: [],
  },

  actions: {
  },
  mutations: {
    setLanguages: set('ttvLanguages'),
  },
}
