import Vue from 'vue'
import axios from 'axios'
import './plugins/base'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './plugins/i18n'
import vuetify from './plugins/vuetify'
import { sync } from 'vuex-router-sync'

// import plugin
import VueToastr from 'vue-toastr'

sync(store, router)

Vue.config.productionTip = false

Vue.prototype.$http = axios
Vue.use(VueToastr)

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App),
}).$mount('#app')
