import { set } from '@/utils/vuex'
import Web3 from 'web3'
import { tokenAddress, abi } from '@/betcoin'
import ggAbi from '@/GoodGame.json'
import axios from 'axios'

export default {
  namespaced: true,

  state: {
    tokenAddress,
    connected: false,
    wsAuth: false, // ws auth sign
    unlocked: false, // ws node sign
    curWs: undefined,
    web3: undefined, // lib
    provider: undefined, // lib
    address: undefined, // lib
    totalBalance: 0,
    contract: undefined, // lib
    platformContract: undefined, // lib
    // public profile..
    profileName: '???',
    profile: null,
    need_cfg: false,
    // test
    totalOnline: 0,
    creditList: {},
    lockedBalance: 0,
    stackedBalance: 0,
  },
  getters: {
    address: (state, getters) => {
      return state.address
    },
  },
  actions: {
    async addCredits ({ state }, { auth, value }) {
      // create new object..
      var creds = JSON.parse(JSON.stringify(state.creditList))
      creds[auth] = (creds[auth] || 0) + value
      state.creditList = creds
      state.totalBalance -= value
    },
    signMsg: async ({ commit, state }, jmsg) => {
      if (!state.unlocked) return // not unlocked, we cant sign?
      jmsg.sign = state.unlocked
      console.log('sendmsg ' + JSON.stringify(jmsg))
      state.curWs.send(JSON.stringify(jmsg))
    },
    sendMsg: async ({ commit, state }, test) => {
      console.log('sendmsg ' + test)
      state.curWs.send(test)
    },
    initW3: ({ state }) => {
      var provider = new Web3.providers.WebsocketProvider('wss://ws-mumbai.matic.today/')
      var web3 = new Web3(provider)
      state.web3 = web3
      // grab nodes..
      state.platformContract = new web3.eth.Contract(ggAbi.abi, ggAbi.address)
      state.contract = new web3.eth.Contract(abi, tokenAddress)
    },
    getBalance: async ({ state, rootState }) => {
      state.contract.methods.balanceOf(rootState.rpc.address).call().then(f => { state.totalBalance = f })
    },
    connect: async ({ commit, state, rootState, dispatch }, router) => {
      // let web3
      const web3 = new Web3(window.ethereum)
      try {
        await window.ethereum.request({ method: 'eth_requestAccounts' }) // await window.ethereum.enable()
      } catch (err) {
        console.log(err)
      }
      state.web3 = web3
      var provider = window.ethereum
      web3.setProvider(provider)
      state.platformContract = new web3.eth.Contract(ggAbi.abi, ggAbi.address)
      state.contract = new web3.eth.Contract(abi, tokenAddress)
      if (provider.chainId !== '0x13881') {
        provider.request({ method: 'wallet_addEthereumChain', params: [{ chainId: '0x13881', chainName: 'Matic Mumbai Testnet', nativeCurrency: { name: 'maticmum', symbol: 'MATIC', decimals: 18 }, rpcUrls: ['https://rpc-mumbai.matic.today'], blockExplorerUrls: ['https://mumbai-explorer.matic.today/'] }] })
        commit('snackbar/setSnackbar', {
          msg: 'Please use the matic mumbai network!',
          color: 'warning',
        }, { root: true })
        commit('snackbar/setValue', true, { root: true })

        /* provider.request({ method: 'wallet_addEthereumChain', params: [{ chainId: '0x89', chainName: 'Matic Mainnet', nativeCurrency: { name: 'MATIC', symbol: 'MATIC', decimals: 18 }, rpcUrls: ['https://rpc-mainnet.maticvigil.com'], blockExplorerUrls: ['https://explorer.matic.network/'] }] }) */

        /* window.ethereum.request({ method: 'wallet_addEthereumChain', params: [{ chainId: '0x38', chainName: 'Binance Smart Chain', nativeCurrency: { name: 'BNB', symbol: 'BNB', decimals: 18 }, rpcUrls: ['https://bsc-dataseed.binance.org/'], blockExplorerUrls: ['https://bscscan.com/'] }] } */
        return // console.log('please use goerli network!')
      }
      commit('setProvider', provider)

      var accs = await web3.eth.getAccounts()
      var address = accs[0]
      commit('setAddress', address)

      window.ethereum.on('accountsChanged', async function (accounts) {
        console.log('updata', accounts)

        var accs = await web3.eth.getAccounts()
        var address = accs[0]
        commit('setAddress', address)
        await state.contract.methods.balanceOf(address).call().then(f => { state.totalBalance = f })
        // update balance, profile and etc...
        state.undefined = false
        state.unlocked = false
        dispatch('disconnect')
      })
      // grab contract..

      await state.contract.methods.balanceOf(address).call().then(f => { state.totalBalance = f })

      /* var bal = await state.platformContract.methods.balance().call({ from: address })
      state.stackedBalance = bal[0]
      state.lockedBalance = bal[1] */
      /* var nodes = {}
      await contract.getPastEvents('SetNode', { // publishnode?
        fromBlock: 0,
        toBlock: 'latest',
      }, async function (error, events) {
        if (error) {
          throw new Error('bad events, check RPC')
        }
        // console.log(events);
        for (var i = 0; i < events.length; i++) {
          var e = events[i].returnValues
          var addr = e.authority
          // if(window.webpackHotUpdate ) e['nodeUrl'] = '127.0.0.1:8001';
          if (e.tag === 'brawl') e.nodeUrl = '127.0.0.1:8002'
          if (e.tag === 'fortnite') e.nodeUrl = '127.0.0.1:8003'
          nodes[addr] = { addr, tag: e.tag, host: e.nodeUrl }
        }
      })
      var nnodes = []
      for (var n in nodes) {
        nnodes.push(nodes[n])
      }
      state.nodes = nnodes
      console.log('nodes', nnodes) */

      // connect to ws..
      dispatch('connectWs', router)
    },
    async connectWs ({ commit, state, rootState, dispatch }, router) {
      var addr = rootState.rpc.address
      console.log('aaddr', addr)
      if (!state.wsAuth) state.wsAuth = 'testsign' // await web3.eth.sign(state.web3.utils.sha3('wsControl'), addr)
      console.log('baddr', addr)
      var host = rootState.platform.mainHost
      var ws
      if (host.substr(0, 5) === 'https') ws = 'wss://' + host.substr(7) + '/?auth=' + state.wsAuth
      else if (host.substr(0, 4) === 'http') ws = 'ws://' + host.substr(6) + '/?auth=' + state.wsAuth
      var curWs = new WebSocket(ws)
      console.log('ws', curWs)
      curWs.onclose = function () {
        console.log('ws disconnect..')
        dispatch('disconnect')
      }
      curWs.onopen = function () {
        state.curWs = curWs
        commit('setConnected', true)
      }
      curWs.onmessage = async function (e) {
        var j = JSON.parse(e.data)
        console.log('recv msg', j)
        if (j.msg === 'do_unlock') {
          state.unlocked = true
          document.cookie = 'ws_id=' + j.id + '; path=/' // set temporary cookie
        } else if (j.msg === 'gg_stats') {
          state.totalOnline = j.online
        } else if (j.msg === 'credit_list' && j.list) {
          state.creditList = j.list
        /* }
        else if (j.msg === 'join_tournamnet') {
          if (rootState.platform.tournaments[j.id]) {
            var tourney = Object.assign({}, rootState.platform.tournaments[j.id])
            tourney.players[j.slot] = j.addr
            rootState.platform.tournaments[j.id] = tourney
          } */
        } else if (j.msg === 'friend_list' && j.list) {
          rootState.friends.friend_list = j.list
          var presponse = await axios.get(host + '/profiles?profiles=' + JSON.stringify(Object.keys(j.list)))
          dispatch('profiles/parseProfiles', presponse.data, { root: true })
        } else if (j.msg === 'update_profile') {
          if (j.addr === state.address) {
            state.profile = j.profile
            state.profileName = j.profile.display
            var profiles = {}
            profiles[j.addr] = { display: state.profileName }
            dispatch('profiles/parseProfiles', profiles, { root: true })
          }
        } else if (j.msg === 'need_cfg') {
          // router.push('/profile')
          state.need_cfg = true
        } else if (j.msg === 'toastr') {
          console.log('toastr', j.text)
          commit('snackbar/setSnackbar', {
            msg: j.text,
            color: 'warning',
          }, { root: true })
          commit('snackbar/setValue', true, { root: true })
        } else if (j.msg === 'text_friend') {
          dispatch('chats/addChat', { recipient: j.from, addr: j.from, text: j.text }, { root: true })
        }/* else if (j.msg === 'online') {
          var list = Object.assign({}, rootState.friends.friend_list)
          list[j.addr] = 2
          rootState.friends.friend_list = list
        } else if (j.msg === 'offline') {
          var flist = Object.assign({}, rootState.friends.friend_list)
          flist[j.addr] = 1
          rootState.friends.friend_list = flist
        } */
      }
    },
    disconnect: async ({ commit, dispatch, state, rootState }) => {
      console.log('called close!', state)
      rootState.friends.friend_list = {}
      state.profileName = '???'
      state.profileAvatar = ''
      state.need_cfg = false

      state.wsAuth = undefined
      state.unlocked = false
      if (state.curWs) state.curWs.close()
      commit('setConnected', false)
    },
  },

  mutations: {
    setAddress: set('address'),
    setProvider: set('provider'),
    setConnected: set('connected'),
    setUnlocked: set('unlocked'),
  },
}
